/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

import holographic from "../../../content/assets/amera-assets/holographic.jpg";

export const styles = {
  featureOneContainer: {
    position: `relative`,
    py: [3],
    "::before, ::after": {
      position: `absolute`,
      content: `" "`,
      size: `70vw`,
      maxWidth: `500px`,
      maxHeight: `500px`,
      top: `55%`,
      left: `0%`,
      transform: [`translate(15vw, -185%)`, `translate(20%, -85%)`],
      zIndex: -2,
      borderRadius: `full`,
    },
    "::after": {
      background: `linear-gradient(
          180deg,
          rgba(255,255,255,0.2) 0%,
          rgba(255,255,255,0.9) 100%
        )`,
    },
    "::before": {
      background: `url(${holographic}) no-repeat center center`,
      backgroundSize: `cover`,
    },
  },
  featureTwoContainer: {
    position: `relative`,
    py: [3],
    "::before, ::after": {
      position: `absolute`,
      content: `" "`,
      size: `70vw`,
      maxWidth: `500px`,
      maxHeight: `500px`,
      top: `55%`,
      right: `0%`,
      transform: [`translate(-15vw, 30%)`, `translate(-15%, -85%)`],
      zIndex: -2,
      borderRadius: `full`,
    },
    "::after": {
      background: `linear-gradient(
          180deg,
          rgba(255,255,255,0.2) 0%,
          rgba(255,255,255,0.9) 100%
        )`,
    },
    "::before": {
      background: `url(${holographic}) no-repeat center center`,
      backgroundSize: `cover`,
    },
  },
};
