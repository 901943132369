import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "theme-ui";
import Layout from "@solid-ui-layout/Layout";
import Seo from "@solid-ui-components/Seo";
import Divider from "@solid-ui-components/Divider";
import ModalWithTabs from "@solid-ui-blocks/Modal/Block01";
// import ModalSimple from "@solid-ui-blocks/Modal/Block02";
import Header from "@solid-ui-blocks/Header/Block01";
import FeatureOne from "@solid-ui-blocks/FeaturesWithPhoto/Block07";
import GetStarted from "@solid-ui-blocks/CallToAction/Block01";
import Footer from "@solid-ui-blocks/Footer/Block01";
import { normalizeBlockContentNodes } from "@blocks-helpers";
import theme from "./_theme";
import { styles } from "./styles/_products.styles";
import StackTitle from "@solid-ui-components/StackTitle";

const Products = (props) => {
  let preSelectedLanguage = 'en';
  if (typeof window !== 'undefined') {
    preSelectedLanguage = localStorage.getItem("selectedLanguage");
  } 
  
  const { allBlockContent, site } = useStaticQuery(query);
  const [selectedlanguage, setSelectedlanguage] = useState(
    preSelectedLanguage
      ? preSelectedLanguage
      : site.siteMetadata.languages.defaultLangKey
  );

  const content = normalizeBlockContentNodes(allBlockContent?.nodes);

  function switchLanguage(lang) {
    setSelectedlanguage(lang);
  }

  if (typeof window !== 'undefined') {
    localStorage.setItem("selectedLanguage", selectedlanguage);
  }
  
  const products = content[`all-products.${selectedlanguage}`];
  return (
    <Layout theme={theme} {...props}>
      <Seo title="Home" />
      {/* Modals */}
      <ModalWithTabs
        content={content[`authentication.${selectedlanguage}`]}
        reverse
      />
      <ModalWithTabs content={content[`contact.${selectedlanguage}`]} />

      {/* Blocks */}
      <Header
        switchLanguage={switchLanguage}
        languages={
          preSelectedLanguage
            ? [preSelectedLanguage, ...site.siteMetadata.languages.langs]
            : site.siteMetadata.languages.langs
        }
        content={content[`header.${selectedlanguage}`]}
      />
      <Divider space="5" />

      {/* Product Page Title */}
      <Divider space="5" />
      <StackTitle
        kicker={products.kicker}
        title={products.title}
        lines={[products.text_1, products.text_2]}
      />

      {/* Amera Products */}
      <Divider space="4" />
      {products?.collection?.map((data, index) => {
        const rev = index % 2 === 0 ? "reverse" : "";
        const stl =
          index % 2 === 0
            ? styles.featureTwoContainer
            : styles.featureOneContainer;
        return (
          <Container variant="wide" sx={stl}>
            <FeatureOne content={data} reverse={rev} />
          </Container>
        );
      })}
      <Divider space="5" />

      {/* Amera Early Access */}
      <Container sx={styles.getStartedContainer}>
        <GetStarted content={content[`book-demo.${selectedlanguage}`]} />
      </Container>

      <Divider space="5" />
      <Footer content={content[`footer.${selectedlanguage}`]} />
    </Layout>
  );
};

const query = graphql`
  query siteProductsBlockContent {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    allBlockContent(
      filter: {
        page: {
          in: [
            "site/products/products"
            "site/shared/header"
            "site/shared/footer"
            "site/shared/contact"
            "site/shared/authentication"
            "site/shared/book-demo"
          ]
        }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`;

export default Products;
